import $ from 'jquery';

$('.input-auto-select')
  .on('focus', function () {
    var input = this;
    window.setTimeout(function () {
      input.select();
    }, 1);
  })
  .attr('readonly', 'readonly');
